@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 768px) {
    :root, label, a, button, input {
        cursor: none;
    }

    .fslightbox-container {
        cursor: hand;
    }

    .fslightbox-container .fslightbox-cursor-grabbing {
        cursor: grabbing !important;
    }
}

body {
    --max-width: 1440px;
}

body:not(.password-protected) {
    --max-width: calc(1440px + var(--scrollbar-width));
}

*:focus {
    outline: none;
}

.font-serif {
    letter-spacing: 0.04em;
}

main,
.landing-fixed-text {
    max-width: 1440px;
}

.landing-container {
    width: 100%;
    height: calc(100vh - 200px);
    background-color: #fafafa;
    position: relative;
}

.landing-fixed-text {
    color: #222222;
    font-size: 1.5rem;
    line-height: 32px;
}

@media (min-width: 768px) {
    .landing-fixed-text {
        font-size: 2rem;
        line-height: 42px;
    }
}

.main-container {
    width: 100%;
    min-height: 100vh;
    background-color: white;
    position: relative;
}

.case-study-half {
    width: 100%;
}

.view-case-study-trigger .case-study-half img {
    transition: transform 200ms;
}

.view-case-study-trigger:hover .case-study-half img {
    transform: scale(1.05);
}

@media (min-width: 768px) {
    .case-study-half {
        width: calc(50% - 5px);
    }
}

.work-experience-link-title,
.work-experience-link-year,
.work-experience-link-cta span,
.work-experience-link-cta svg path.path-stroke,
.work-experience-link-cta svg path.path-fill {
    transition: all 200ms;
}

.work-experience-link-cta span  {
    opacity: 0;
}

.work-experience-link:hover .work-experience-link-title,
.work-experience-link:hover .work-experience-link-year {
    color: #222222;
}

.work-experience-link:hover .work-experience-link-title {
    font-size: 20px;
}

.work-experience-link:hover .work-experience-link-year {
    font-size: 16px;
}

.work-experience-link:hover .work-experience-link-cta span {
    opacity: 1;
}

.work-experience-link:hover .work-experience-link-cta svg path.path-stroke {
    stroke: #222222;
}

.work-experience-link:hover .work-experience-link-cta svg path.path-fill {
    fill: #222222;
}

.about-me-face-hover {
    transform: translate(-.3rem, -.26rem);
}

.about-me-link:hover .about-me-face-default {
    opacity: 0;
}

.about-me-link:hover .about-me-face-hover {
    opacity: 1;
}

.copy-email-container {
    height: 22px;
    transform: translate(-9.25rem, -.5rem);
    cursor: pointer;
}

@media (min-width: 768px) {
    .copy-email-container {
        transform: translate(-9.2rem, -.5rem);
        cursor: none;
    }
}

.copy-email {
    transition: transform ease-in-out 200ms;
}

.copy-email.copied {
    transform: translateY(-22px);
}

@keyframes mouse-spin {
    100% {
        transform: rotate(-360deg);
    }
}

@keyframes mouse-spin-cw {
    100% {
        transform: rotate(720deg);
    }
}

#mouse {
    width: 100px;
    height: 100px;
    display: none;
}

@media (min-width: 768px) {
    #mouse {
        display: flex;
    }
}

#mouse .mouse-circle {
    transition: r 200ms;
    stroke: #222222;
    stroke-width: 1px;
    fill: rgba(255, 255, 255, .6);
    position: relative;
    top: 50%;
    right: 50%;
}

#mouse.view-case-study .mouse-circle {
    r: 49px;
}

#mouse .mouse-view-case-study-inner {
    opacity: 0;
    transition: all 200ms;
    transform: scale(.01);
}

#mouse.view-case-study .mouse-view-case-study-inner {
    opacity: 1;
    transform: scale(1);
}

#mouse.view-case-study .mouse-view-case-study-inner .mouse-spin {
    animation: mouse-spin 7s linear infinite;
}

#mouse.view-case-study .mouse-view-case-study-inner .mouse-spin-cw {
    animation: mouse-spin-cw 7s linear infinite;
}

.referral-container .referral-img-container,
.navbar-title-width {
    width: 84px;
}

.referral-container .referral-img-container img {
    transform: translateX(42px);
    height: 84px;
}

.referral-container .referral-text-container {
    width: calc(100% - 84px);
    padding-top: 2.35rem;
    padding-bottom: 2.35rem;
}

@media (min-width: 768px) {
    .referral-container .referral-img-container,
    .navbar-title-width {
        width: 128px;
    }

    .referral-container .referral-img-container img {
        transform: translateX(64px);
        height: 128px;
    }

    .referral-container .referral-text-container {
        width: calc(100% - 128px);
    }

}

.gallery-grid-container {
    display: grid;
    padding: 16px;
    gap: 8px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(2, 1fr);
}

.gallery-grid-container .gallery-grid-item {
    cursor: pointer;
}

.gallery-grid-container .gallery-grid-item .gallery-grid-item-image-container {
    overflow: hidden;
}

.gallery-grid-container .gallery-grid-item .gallery-grid-item-image-container .gallery-grid-item-image {
    background-position: center center;
    background-size: cover;
    transition: all 200ms;
}

.gallery-grid-container .gallery-grid-item:hover .gallery-grid-item-image-container .gallery-grid-item-image {
    transform: scale(1.1);
    filter: grayscale(1);
}

.gallery-grid-container .gallery-grid-item {
    grid-column: span 2;
    height: 328px;
}

@media (min-width: 640px) {
    .gallery-grid-container .gallery-grid-item:nth-child(3n + 1) {
        grid-column: span 2;
        height: 328px;
    }
    .gallery-grid-container .gallery-grid-item:nth-child(3n + 2),
    .gallery-grid-container .gallery-grid-item:nth-child(3n + 3) {
        grid-column: span 1;
        height: 311px
    }
}

@media (min-width: 768px) {
    .gallery-grid-container .gallery-grid-item {
        cursor: none;
    }

    .gallery-grid-container {
        padding: 40px;
        gap: 12px;
    }

    .gallery-grid-container {
        grid-template-columns: repeat(6, 1fr);
    }

    .gallery-grid-container .gallery-grid-item:nth-child(5n + 1),
    .gallery-grid-container .gallery-grid-item:nth-child(5n + 2) {
        grid-column: span 3;
        height: 328px;
    }
    .gallery-grid-container .gallery-grid-item:nth-child(5n + 3),
    .gallery-grid-container .gallery-grid-item:nth-child(5n + 4),
    .gallery-grid-container .gallery-grid-item:nth-child(5n + 5) {
        grid-column: span 2;
        height: 311px;
    }
}

@media (min-width: 1280px) {
    .gallery-grid-container {
        grid-template-columns: repeat(15, 1fr);
    }

    .gallery-grid-container .gallery-grid-item:nth-child(8n + 1),
    .gallery-grid-container .gallery-grid-item:nth-child(8n + 2),
    .gallery-grid-container .gallery-grid-item:nth-child(8n + 3) {
        grid-column: span 5;
        height: 328px;
    }
    .gallery-grid-container .gallery-grid-item:nth-child(8n + 4),
    .gallery-grid-container .gallery-grid-item:nth-child(8n + 5),
    .gallery-grid-container .gallery-grid-item:nth-child(8n + 6),
    .gallery-grid-container .gallery-grid-item:nth-child(8n + 7),
    .gallery-grid-container .gallery-grid-item:nth-child(8n + 8) {
        grid-column: span 3;
        height: 311px;
    }
}

.password-protected {
    overflow-y: hidden;
}

.password-protected-content {
    min-height: calc(100vh - 108px);
}

.password-overlay {
    top: 54px;
    left: max(calc((100vw - var(--max-width)) / 2), 0);
    width: 100vw;
    max-width: 1440px;
    height: calc(100vh - 54px);
    opacity: 0;
    transition: opacity ease-in 500ms;
    z-index: 40;
    pointer-events: none;
}

.password-protected .password-overlay {
    opacity: 1;
    pointer-events: auto;
}

.password-protected footer {
    bottom: 0;
    position: sticky;
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(8px);
    }
    75% {
        transform: translateX(-8px);
    }
    100% {
        transform: translateX(0);
    }
}

#case-study-password-form-group.shake {
    animation: shake 200ms ease-in-out 0s 2;
}

#case-study-wrong-password {
    opacity: 0;
    transition: opacity 200ms;
}

#case-study-wrong-password.shown {
    opacity: 1;
}

.case-study-header {
    width: 100vw;
    max-width: 1440px;
}

.case-study-wall-container {
    height: 100vh;
    max-height: calc(100vh - 54px);
}

@media (min-width: 600px) {
    .case-study-wall {
        transform: translateY(-100px);
    }
}

/*this is testing the prose page

/* Hide all sections by default */
  .hide {
    display: none;
  }