*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #d7d7d7;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: DM Mono, ui-monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #969696;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.bottom-12 {
  bottom: 3rem;
}

.right-12 {
  right: 3rem;
}

.top-0 {
  top: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-fit {
  height: fit-content;
}

.h-full {
  height: 100%;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.flex-1 {
  flex: 1;
}

.-translate-y-1 {
  --tw-translate-y: -.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(34 34 34 / var(--tw-border-opacity));
}

.border-y-gray-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(215 215 215 / var(--tw-border-opacity));
  border-bottom-color: rgb(215 215 215 / var(--tw-border-opacity));
}

.border-b-gray-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(215 215 215 / var(--tw-border-opacity));
}

.border-l-gray-200 {
  --tw-border-opacity: 1;
  border-left-color: rgb(215 215 215 / var(--tw-border-opacity));
}

.border-r-gray-200 {
  --tw-border-opacity: 1;
  border-right-color: rgb(215 215 215 / var(--tw-border-opacity));
}

.border-t-gray-200 {
  --tw-border-opacity: 1;
  border-top-color: rgb(215 215 215 / var(--tw-border-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 244 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(119 119 119 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 34 34 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-0 {
  padding: 0;
}

.p-10 {
  padding: 2.5rem;
}

.p-4 {
  padding: 1rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-mono {
  font-family: DM Mono, ui-monospace;
}

.font-serif {
  font-family: Cooper BT, ui-serif;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-5 {
  line-height: 1.25rem;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(150 150 150 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(119 119 119 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.decoration-wavy {
  text-decoration-style: wavy;
}

.opacity-0 {
  opacity: 0;
}

.grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

@media (width >= 768px) {
  :root, label, a, button, input {
    cursor: none;
  }

  .fslightbox-container {
    cursor: hand;
  }

  .fslightbox-container .fslightbox-cursor-grabbing {
    cursor: grabbing !important;
  }
}

body {
  --max-width: 1440px;
}

body:not(.password-protected) {
  --max-width: calc(1440px + var(--scrollbar-width));
}

:focus {
  outline: none;
}

.font-serif {
  letter-spacing: .04em;
}

main, .landing-fixed-text {
  max-width: 1440px;
}

.landing-container {
  background-color: #fafafa;
  width: 100%;
  height: calc(100vh - 200px);
  position: relative;
}

.landing-fixed-text {
  color: #222;
  font-size: 1.5rem;
  line-height: 32px;
}

@media (width >= 768px) {
  .landing-fixed-text {
    font-size: 2rem;
    line-height: 42px;
  }
}

.main-container {
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.case-study-half {
  width: 100%;
}

.view-case-study-trigger .case-study-half img {
  transition: transform .2s;
}

.view-case-study-trigger:hover .case-study-half img {
  transform: scale(1.05);
}

@media (width >= 768px) {
  .case-study-half {
    width: calc(50% - 5px);
  }
}

.work-experience-link-title, .work-experience-link-year, .work-experience-link-cta span, .work-experience-link-cta svg path.path-stroke, .work-experience-link-cta svg path.path-fill {
  transition: all .2s;
}

.work-experience-link-cta span {
  opacity: 0;
}

.work-experience-link:hover .work-experience-link-title, .work-experience-link:hover .work-experience-link-year {
  color: #222;
}

.work-experience-link:hover .work-experience-link-title {
  font-size: 20px;
}

.work-experience-link:hover .work-experience-link-year {
  font-size: 16px;
}

.work-experience-link:hover .work-experience-link-cta span {
  opacity: 1;
}

.work-experience-link:hover .work-experience-link-cta svg path.path-stroke {
  stroke: #222;
}

.work-experience-link:hover .work-experience-link-cta svg path.path-fill {
  fill: #222;
}

.about-me-face-hover {
  transform: translate(-.3rem, -.26rem);
}

.about-me-link:hover .about-me-face-default {
  opacity: 0;
}

.about-me-link:hover .about-me-face-hover {
  opacity: 1;
}

.copy-email-container {
  cursor: pointer;
  height: 22px;
  transform: translate(-9.25rem, -.5rem);
}

@media (width >= 768px) {
  .copy-email-container {
    cursor: none;
    transform: translate(-9.2rem, -.5rem);
  }
}

.copy-email {
  transition: transform .2s ease-in-out;
}

.copy-email.copied {
  transform: translateY(-22px);
}

@keyframes mouse-spin {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes mouse-spin-cw {
  100% {
    transform: rotate(720deg);
  }
}

#mouse {
  width: 100px;
  height: 100px;
  display: none;
}

@media (width >= 768px) {
  #mouse {
    display: flex;
  }
}

#mouse .mouse-circle {
  stroke: #222;
  stroke-width: 1px;
  fill: #fff9;
  transition: r .2s;
  position: relative;
  top: 50%;
  right: 50%;
}

#mouse.view-case-study .mouse-circle {
  r: 49px;
}

#mouse .mouse-view-case-study-inner {
  opacity: 0;
  transition: all .2s;
  transform: scale(.01);
}

#mouse.view-case-study .mouse-view-case-study-inner {
  opacity: 1;
  transform: scale(1);
}

#mouse.view-case-study .mouse-view-case-study-inner .mouse-spin {
  animation: 7s linear infinite mouse-spin;
}

#mouse.view-case-study .mouse-view-case-study-inner .mouse-spin-cw {
  animation: 7s linear infinite mouse-spin-cw;
}

.referral-container .referral-img-container, .navbar-title-width {
  width: 84px;
}

.referral-container .referral-img-container img {
  height: 84px;
  transform: translateX(42px);
}

.referral-container .referral-text-container {
  width: calc(100% - 84px);
  padding-top: 2.35rem;
  padding-bottom: 2.35rem;
}

@media (width >= 768px) {
  .referral-container .referral-img-container, .navbar-title-width {
    width: 128px;
  }

  .referral-container .referral-img-container img {
    height: 128px;
    transform: translateX(64px);
  }

  .referral-container .referral-text-container {
    width: calc(100% - 128px);
  }
}

.gallery-grid-container {
  grid-auto-flow: dense;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  padding: 16px;
  display: grid;
}

.gallery-grid-container .gallery-grid-item {
  cursor: pointer;
}

.gallery-grid-container .gallery-grid-item .gallery-grid-item-image-container {
  overflow: hidden;
}

.gallery-grid-container .gallery-grid-item .gallery-grid-item-image-container .gallery-grid-item-image {
  background-position: center;
  background-size: cover;
  transition: all .2s;
}

.gallery-grid-container .gallery-grid-item:hover .gallery-grid-item-image-container .gallery-grid-item-image {
  filter: grayscale();
  transform: scale(1.1);
}

.gallery-grid-container .gallery-grid-item {
  grid-column: span 2;
  height: 328px;
}

@media (width >= 640px) {
  .gallery-grid-container .gallery-grid-item:nth-child(3n+1) {
    grid-column: span 2;
    height: 328px;
  }

  .gallery-grid-container .gallery-grid-item:nth-child(3n+2), .gallery-grid-container .gallery-grid-item:nth-child(3n+3) {
    grid-column: span 1;
    height: 311px;
  }
}

@media (width >= 768px) {
  .gallery-grid-container .gallery-grid-item {
    cursor: none;
  }

  .gallery-grid-container {
    grid-template-columns: repeat(6, 1fr);
    gap: 12px;
    padding: 40px;
  }

  .gallery-grid-container .gallery-grid-item:nth-child(5n+1), .gallery-grid-container .gallery-grid-item:nth-child(5n+2) {
    grid-column: span 3;
    height: 328px;
  }

  .gallery-grid-container .gallery-grid-item:nth-child(5n+3), .gallery-grid-container .gallery-grid-item:nth-child(5n+4), .gallery-grid-container .gallery-grid-item:nth-child(5n+5) {
    grid-column: span 2;
    height: 311px;
  }
}

@media (width >= 1280px) {
  .gallery-grid-container {
    grid-template-columns: repeat(15, 1fr);
  }

  .gallery-grid-container .gallery-grid-item:nth-child(8n+1), .gallery-grid-container .gallery-grid-item:nth-child(8n+2), .gallery-grid-container .gallery-grid-item:nth-child(8n+3) {
    grid-column: span 5;
    height: 328px;
  }

  .gallery-grid-container .gallery-grid-item:nth-child(8n+4), .gallery-grid-container .gallery-grid-item:nth-child(8n+5), .gallery-grid-container .gallery-grid-item:nth-child(8n+6), .gallery-grid-container .gallery-grid-item:nth-child(8n+7), .gallery-grid-container .gallery-grid-item:nth-child(8n+8) {
    grid-column: span 3;
    height: 311px;
  }
}

.password-protected {
  overflow-y: hidden;
}

.password-protected-content {
  min-height: calc(100vh - 108px);
}

.password-overlay {
  top: 54px;
  left: max(calc((100vw - var(--max-width)) / 2), 0);
  opacity: 0;
  z-index: 40;
  pointer-events: none;
  width: 100vw;
  max-width: 1440px;
  height: calc(100vh - 54px);
  transition: opacity .5s ease-in;
}

.password-protected .password-overlay {
  opacity: 1;
  pointer-events: auto;
}

.password-protected footer {
  position: sticky;
  bottom: 0;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(8px);
  }

  75% {
    transform: translateX(-8px);
  }

  100% {
    transform: translateX(0);
  }
}

#case-study-password-form-group.shake {
  animation: .2s ease-in-out 2 shake;
}

#case-study-wrong-password {
  opacity: 0;
  transition: opacity .2s;
}

#case-study-wrong-password.shown {
  opacity: 1;
}

.case-study-header {
  width: 100vw;
  max-width: 1440px;
}

.case-study-wall-container {
  height: 100vh;
  max-height: calc(100vh - 54px);
}

@media (width >= 600px) {
  .case-study-wall {
    transform: translateY(-100px);
  }
}

.hide {
  display: none;
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(68 68 68 / var(--tw-bg-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

@media (width >= 640px) {
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 768px) {
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:border-t-0 {
    border-top-width: 0;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pl-5 {
    padding-left: 1.25rem;
  }

  .md\:pr-5 {
    padding-right: 1.25rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
/*# sourceMappingURL=index.d505f965.css.map */
